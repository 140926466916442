<p-toast>
  <ng-template let-message pTemplate="message">
      <div class="d-flex align-items-center">
        <p-avatar image="assets/images/side-logo.png" shape="circle" />
        <p>
          {{ message.detail }}
        </p>
      </div>
      <!-- <div class="font-medium text-lg my-3 text-900">
        {{ message.detail }}
      </div> -->
  </ng-template>
</p-toast>
<div class="loader-container">
  <ngx-loading-bar [includeSpinner]="false" [color]="'#BFC9CA '" [height]="'4px'"></ngx-loading-bar>
  <!-- <mat-progress-bar mode="indeterminate" *ngIf="loaderService.isLoading | async" ></mat-progress-bar> -->
</div>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="large"
  color="transparent"
  style="height: 100%"
  type="ball-scale-multiple"
>
  <div class="loader-inner line-scale-pulse-out">
    <div></div>
    <div></div>
    <img src="../assets/images/a-log.png" alt="" />
  </div>

  <div class="loader-inner-dark line-scale-pulse-out">
    <p class="icon">
      <span class="first"></span>
      <span class="second"></span>
    </p>
    <div></div>
    <div></div>
  </div>
</ngx-spinner>

<router-outlet></router-outlet>
<p-scrollTop />